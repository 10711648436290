
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
// mock
import ProductForm from "../../sections/@dashboard/employees/EmployeeForm";



// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function SaveEmployee() {

  const { t, i18n } = useTranslation();
  
  
  useEffect(() => {
    i18n.changeLanguage("es")
  }, [])

  return (
    <Page title={t('employee_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('employee_form')}
          </Typography>
        </Stack>
        <Card style={{padding: "50px"}}>
         <ProductForm />
        </Card>
      </Container>
    </Page>
  );
}
