import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../Iconify';


// ----------------------------------------------------------------------

export default function MoreMenu(props) {
  const {url, onDelete, menuItems} = props
  const {t} = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleDelete = () => {
    onDelete(true);
    setIsOpen(false);
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        {menuItems || <>
          <MenuItem component={RouterLink} to={url} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText  primary={t('edit')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* <MenuItem onClick={handleDelete} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete"  primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
        </>}
        
      </Menu>
    </>
  );
}
