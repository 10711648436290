import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Stack, Link} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css'
import { paymentTypes } from '../../../constants';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from "../../../components/hook-form/RHFSelect";
import { useApi } from '../../../hooks/useApi';
import tracker from "../../../api/tracker";
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';
import RHFMultiSelect from '../../../components/hook-form/RHFMultiSelect';

// ----------------------------------------------------------------------

export default function ConstructionEmployeeForm({construction}) {

  const {t} = useTranslation();
  const {doSave} = useApi();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([])
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);

  
  const fetchEmployees = async () =>{
    const result = await tracker.get('employees/dropdown')
  
    setEmployees(result.data.data)
  }

  useEffect(() => {
    if(construction){
      setValue('employees',construction.employee_ids.map(value => value.value))
      setSelectedEmployees(construction.employee_ids)
    }
    fetchEmployees();
  }, [construction])

  const ConstructionSchema = Yup.object().shape({
    employees: Yup.array().required(t('Required')),
  });

  const defaultValues = {
    employees: []
  };

  const methods = useForm({
    resolver: yupResolver(ConstructionSchema),
    defaultValues,
  });

  const {
    handleSubmit,
      setValue,
    formState: { isSubmitting },
    watch
  } = methods;

  const onSubmit = async (data) => {
    console.log(data)
    const response = await doSave(`construction_sites/${construction.id}/assignEmployees`, data, t('employees_saved'), `/dashboard/constructions/edit/${construction.id}`, false);

  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      
      <Stack spacing={3}>
        <Stack spacing={1} direction={"row"}>
          <RHFMultiSelect options={employees} initialValues={selectedEmployees}  name="employees" label={t('select_employees')} />
        </Stack>
        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/constructions">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
