import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate,  Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Alert } from '@mui/material';

import { LoadingButton } from '@mui/lab';
// components
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {login} from '../../../slices/userSlice'



// ----------------------------------------------------------------------

export default function LoginForm() {

  const { t } = useTranslation();
    
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t("Digite un usuario")),
    password: Yup.string().required(t("Password_is_required")),
  });

  const handleIncorrectCredentials = () => {
    setErrorMessage(t('incorrect_credentials'));
  }

  const handleServerError = () => {
    setErrorMessage(t('something_went_wrong'));
  }

  const defaultValues = {
    email: '',
    password: '',
    remember: false,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
      setIsLoading(true)
      const result = await dispatch(login(data ))
      if(result.status === 200)
        navigate('/dashboard', { replace: true });
      else if (result.status === 422)
        handleIncorrectCredentials();
      else
        handleServerError();  
      setIsLoading(false)
  };
  console.log(errorMessage)
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label={t("Correo_Electronico")}/>

        <RHFTextField
          name="password"
          label={t('Password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {errorMessage && (
          <Alert severity="error"> {t(errorMessage)}</Alert>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/request-password-reset">
          {t('Forgot_Password')}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Iniciar Sesion
      </LoadingButton>
    </FormProvider>
  );
}
