import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, InputAdornment, InputLabel, ListSubheader, Menu, MenuItem, Select, TextField } from '@mui/material';
import Icofont from 'react-icofont';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

RHFSearchableSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  hasIcons: PropTypes.bool,
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function RHFSearchableSelect({ name, label, options, initialValue, ...other }) {
  const { control, setValue } = useFormContext();
  const {t} = useTranslation();
  const [selectedOption, setSelectedOption] = useState(options.length ? options[0].value : "");

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [searchText]
  );

  const getSelectedOption = () => {
      return selectedOption && options.length ? options.filter((op) => op.value === selectedOption)[0].label : ""
  }

  useEffect(() => {
    if(initialValue)
      setSelectedOption(initialValue)
     console.log(initialValue)
  }, [initialValue])

  return (
    
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
        <FormControl>
          <InputLabel id="search-select-label">{label}</InputLabel>
        <Select
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{ autoFocus: false }}
          {...field}
          fullWidth
          // value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          
          helpertext={error?.message}
          {...other}
          labelId="search-select-label"
          id="search-select"
          value={selectedOption}
          label={label}
          onChange={(e) => {setSelectedOption(e.target.value); setValue(name, e.target.value)}}
          onClose={() => setSearchText("")}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          renderValue={() => getSelectedOption()}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection. */}
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Empieze a escribir para seleccionar..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
       
      )}}
    />
  );
}
