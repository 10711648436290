// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'employees',
    path: '/dashboard/employees',
    icon:  getIcon('mdi:account-group'),
    allowedRoles: null
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
    allowedRoles: ['Administrator']
  },
  {
    title: 'obras',
    path: '/dashboard/constructions',
    icon:  getIcon('mdi:bank'),
    allowedRoles: null
  },
  {
    title: 'préstamos',
    path: '/dashboard/loans',
    icon:  getIcon('mdi:cash-clock'),
    allowedRoles: null
  },
  {
    title: 'Nómina',
    path: '/dashboard/payroll',
    icon:  getIcon('mdi:account-cash'),
    allowedRoles: null
  },
  
];

export default navConfig;
