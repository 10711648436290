import { FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Avatar,
} from '@mui/material';
// components
import { ConfirmationDialog } from "../../components/general/ConfirmationDialog";
import Page from '../../components/Page';
import tracker from "../../api/tracker";
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from "../../hooks/useQuery";
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from "../../hooks/useApi";
import { PinDialog } from "../../components/general/PinDialog";

const override = {  
  margin: "0 auto",
};

export default function PayrollIndex() {
  const [fortnights ,setFortnigths] = useState([]);
  const [nextPayroll ,setNextPayroll] = useState([]);
  // const [selectedEmployee ,setSelectedEmployee] = useState();
  const [pagination, setPagination] = useState({total: 0, per_page: 0, current_page: 1, last_page: 1});
  const [isSubmitting, setIsSumbmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [filter ,setFilter] = useState("");
  const [sort ,setSort] = useState('desc');
  const [sortField ,setSortField] = useState('date_start');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [showNextDialog, setShowNextDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);
  const {doDelete, doSave} = useApi();
  const [loading, setLoading] = useState(false);
  console.log(isLoading)
  
  const fetchData = useQuery();

  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'date_start', label: t('period'), alignRight: false },
    { id: 'closed', label: t('status'), alignRight: false },
    { id: 'id' },
  ];

  const refreshTable = async () => {
    setIsLoading(true)
    fetchLatestPayroll()
    await fetchData('payroll', filter, sort, setFortnigths, sortField, setPagination, page, setNotFound)
    setIsLoading(false)
  }

  const fetchLatestPayroll = async () => {
    const data = await tracker.get('payroll/next');
    setNextPayroll(data.data)
  
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(refreshTable, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filter, sort, page, sortField])


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fortnights.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleNextPayroll = async (pin) => {
    setLoading(true)
    const response = await doSave('payroll/createFortnight', {pin} , 'La quincena fue creada exitosamente')
    console.log(response)
    if(response.response && response.response.status === 423) {
      setPinDialogMessage(response.response.data.message);
      setShowPinDialog(true);
    }
    else {
      setShowNextDialog(false)
      setShowPinDialog(false)
      refreshTable()
    }
    setLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fortnights.length) : 0;

  return (
    <Page title={t('fortnights')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('fortnights')}
          </Typography>
          <Button variant="contained" onClick={() => setShowNextDialog(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            {t('create_next_payroll')}
          </Button>
        </Stack>

        <Card>
        <ListToolbar numSelected={selected.length} filterName={filter} onFilterName={handleFilterByName} placeholder={`search_fortnigths`} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {isLoading?  <FadeLoader color={'blue'} loading={isLoading} cssOverride={override} size={50} /> :
                <>
                <ListHead
                  headLabel={TABLE_HEAD}
                  rowCount={fortnights.length}
                  order={sort}
                  orderBy={sortField}
                  numSelected={selected.length}
                  onRequestSort={setSortField}
                  setSort={setSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                   {fortnights.map((row) => {
                      const { period, id, status} = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                          </TableCell>
                          <TableCell  align="left">{period}</TableCell>
                          <TableCell style={{color: status === 'En proceso' ? 'blue': 'green'}} align="left">{status}</TableCell>
                          <TableCell align="right">
                            <MoreMenu url={`/dashboard/payroll/edit/${id}`}/>
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {notFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filter} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                </>}
                  </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={pagination.total}
            rowsPerPage={pagination.per_page}
            page={page -1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ConfirmationDialog
            open={showNextDialog}
            loading={loading}
            onClose={() => {setShowNextDialog(false)}}
            onConfirm={() => handleNextPayroll(null)}
            message={`Desea generar la quincena del siguiente periodo: ${nextPayroll}?` }
           />
            <PinDialog
            open={showPinDialog}
            onClose={() => {setShowPinDialog(false)}}
            onSubmit={handleNextPayroll}
            loading={loading}
            message={pinDialogMessage}
            free
           />
        </Card>
      </Container>
    </Page>
  );
}
