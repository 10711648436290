import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Stack, Link} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css'
import { paymentTypes } from '../../../constants';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from "../../../components/hook-form/RHFSelect";
import { useApi } from '../../../hooks/useApi';
import tracker from "../../../api/tracker";
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';

// ----------------------------------------------------------------------

export default function ConstructionForm({construction}) {
  
  

  const {t} = useTranslation();
  const {doSave} = useApi();
  const [roles, setRoles] = useState([]);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);

  
  const fetchRoles = async () =>{
    const result = await tracker.get('roles')
    setRoles(result.data.data)
  }

  useEffect(() => {
    if(construction){
      const fields = ['name', 'city', 'id', 'address']
      fields.forEach(field => setValue(field, construction[field]));
    }
    fetchRoles();
  }, [construction])

  const ConstructionSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    city: Yup.string().required(t('Required')),
    address: Yup.string().required(t('Required')),
  });

  const defaultValues = {
    name: '',
    city: '',
    address: '',
  };

  const methods = useForm({
    resolver: yupResolver(ConstructionSchema),
    defaultValues,
  });

  const {
    handleSubmit,
      setValue,
    formState: { isSubmitting },
    watch
  } = methods;

  const watchRole = watch("role");
  const watchPaymentType = watch("payment_type");
  const onSubmit = async (data) => {
    if(data.photos)
      data.photos = data.photos[0];
    else
      data.photos = null;  
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    const response = await doSave('construction_sites', data, t('construction_saved'), '/dashboard/constructions', construction, headers);
    if(response.response.status === 423) {
      setPinDialogMessage(response.response.data.message);
      setShowPinDialog(true);
    }

  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      
      <Stack spacing={3}>
        <Stack spacing={1} direction={"row"}>
          <RHFTextField name="name" label={t('name')} />
          <RHFTextField name="city" label={t('city')} />
          <RHFTextField name="address" label={t('address')} />
        </Stack>
        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/constructions">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
