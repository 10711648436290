import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/users/User';
import Login from './pages/users/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import PrivateRoutes from "./utils/PrivateRoute";
import SaveUser from "./pages/users/SaveUser";
import EditUser from "./pages/users/EditUser";
import RequestPasswordReset from './pages/users/RequestPassordReset';
import ResetPassword from './pages/users/ResetPassword';
import EmployeeIndex from './pages/employees/EmployeeIndex';
import SaveEmployee from './pages/employees/SaveEmployee';
import EditEmployee from './pages/employees/EditEmployee';
import ConstructionIndex from './pages/constructions/ConstructionIndex';
import LoanIndex from './pages/loans/LoanIndex';
import SaveLoan from './pages/loans/SaveLoan';
import EditLoan from './pages/loans/EditLoan';
import SaveConstruction from './pages/constructions/SaveConstruction';
import EditConstruction from './pages/constructions/EditConstruction';
import PayrollIndex from './pages/payroll/PayrollIndex';
import PayrollEdit from './pages/payroll/PayrollEdit';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardApp /> },

            {path: 'user', element: <User />},
            {path: 'user/save', element: <SaveUser />},
            {path: 'user/edit/:hashid', element: <EditUser />},

            {path: 'employees', element: <EmployeeIndex />},
            {path: 'employees/save', element: <SaveEmployee />},
            {path: 'employees/edit/:id', element: <EditEmployee />},
        
            {path: 'loans', element: <LoanIndex />},
            {path: 'loans/save', element: <SaveLoan />},
            {path: 'loans/edit/:id', element: <EditLoan />},

            {path: 'constructions', element: <ConstructionIndex />},
            {path: 'constructions/save', element: <SaveConstruction />},
            {path: 'constructions/edit/:id', element: <EditConstruction />},
            {path: 'payroll', element: <PayrollIndex />},
            {path: 'payroll/edit/:id', element: <PayrollEdit />},
            
          ],
        },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'request-password-reset',
      element: <RequestPasswordReset />,
    },
    {
      path: 'reset-password/:hashid',
      element: <ResetPassword />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/user" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
