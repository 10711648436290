import { FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Avatar,
} from '@mui/material';
// components
import { ConfirmationDialog } from "../../components/general/ConfirmationDialog";
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from "../../hooks/useQuery";
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from "../../hooks/useApi";

const override = {  
  margin: "0 auto",
};

export default function Buyer() {
  const [users ,setUsers] = useState([]);
  const [selectedUser ,setSelectedUser] = useState();
  const [pagination, setPagination] = useState({total: 0, per_page: 0, current_page: 1, last_page: 1});
  const [page, setPage] = useState(1);
  const [filter ,setFilter] = useState("");
  const [sort ,setSort] = useState('asc');
  const [sortField ,setSortField] = useState('name');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const {doDelete} = useApi();
  const [loading, setLoading] = useState(true);
  
  const fetchData = useQuery();
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: t('Name'), alignRight: false },
    { id: 'email', label: t('Email'), alignRight: false },
    { id: 'role', label: t('Role'), alignRight: false },
    { id: '' },
  ];

  const refreshTable = async () => {
    setIsLoading(true)
    await fetchData('users', filter, sort, setUsers, sortField, setPagination, page, setNotFound)
    setIsLoading(false)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(refreshTable, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filter, sort, page, sortField])


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleDeleteDialog = (user) => {
    setSelectedUser(user)
    setShowDeleteDialog(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () =>{
    setLoading(true)
    doDelete(`users/${selectedUser.id}`, t('delete_success'), t('delete_error'), refreshTable);
    setLoading(false); 
    setShowDeleteDialog(false);
    setSelected([]);
  }

  const handleMassDelete = async () => {
    setLoading(true);
    doDelete(`users/mass`, t('delete_mass_success'), t('delete_mass_error'), refreshTable, {ids: selected}, true); 
    setLoading(false);
    setSelected([]);
  }

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  return (
    <Page title={t('Users')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('Users')}
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/user/save" startIcon={<Iconify icon="eva:plus-fill" />}>
            {t('New_User')}
          </Button>
        </Stack>

        <Card>
          <ListToolbar numSelected={selected.length} filterName={filter} onFilterName={handleFilterByName} placeholder={`search_users`} handleMassDelete={handleMassDelete} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {isLoading?  <FadeLoader color={'blue'} loading={loading} cssOverride={override} size={50} /> :
                <>
                <ListHead
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  order={sort}
                  orderBy={sortField}
                  numSelected={selected.length}
                  onRequestSort={setSortField}
                  setSort={setSort}
                  onSelectAllClick={handleSelectAllClick}

                />
                <TableBody>
                   {users.map((row) => {
                      const { name, role, id, email, picture} = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      console.log(selected)
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                         <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={picture} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="right">
                            <MoreMenu url={`/dashboard/user/edit/${id}`} onDelete={() => handleDeleteDialog(row) }/>
                          </TableCell>
                        </TableRow>
                      );
                    }) }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {notFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filter} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                </>}
                  </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={pagination.total}
            rowsPerPage={pagination.per_page}
            page={page -1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ConfirmationDialog
            open={showDeleteDialog}
            onClose={() => {setShowDeleteDialog(false)}}
            onConfirm={handleDelete}
            message={t('delete_confirm')}
           />
        </Card>
      </Container>
    </Page>
  );
}
