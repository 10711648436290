import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Stack, Link, FormControlLabel, Checkbox, Typography, Chip} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css'
import { paymentTypes } from '../../../constants';

// components
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import RHFSelect from "../../../components/hook-form/RHFSelect";
import { useApi } from '../../../hooks/useApi';
import tracker from "../../../api/tracker";
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';


// ----------------------------------------------------------------------

export default function EmployeeForm({employee}) {
  
  

  const {t} = useTranslation();
  const {doSave} = useApi();
  const [roles, setRoles] = useState([]);
  const [showPinDialog, setShowPinDialog] = useState(false);
  const [pinDialogMessage, setPinDialogMessage] = useState(false);

  
  const fetchRoles = async () =>{
    const result = await tracker.get('roles')
    setRoles(result.data.data)
  }

  useEffect(() => {
    if(employee){
      const fields = ['name', 'lastname', 'id', 'phone_number', 'salary', 'bank_account', 'role', 'payment_type', 'personal_id', 'per_capita', 'is_nzv']
      fields.forEach(field => setValue(field, employee[field]));
    }
    fetchRoles();
  }, [employee])

  const EmployeeSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    lastname: Yup.string().required(t('Required')),
    phone_number: Yup.string().required(t('Required')),
    salary: Yup.number().required(t('Required')),
    pin: Yup.number().when("role", {
      is: "Administrator",
      then: Yup.number()
      .max(99999, t('length_validation_max', {length: 5}))
      .min(10000, t('length_validation_min', {length: 5}))
      .required(t('Required')),
      otherwise: Yup.number().notRequired()
    }),
    // bank_account: Yup.number().when("payment_type", {
    //   is: "bank",
    //   then: Yup.number().required(t('Required')),
    //   otherwise: Yup.number().notRequired()
    // }),
    role: Yup.string().required(t('Required')),
    payment_type: Yup.string().required(t('Required')),
    personal_id: Yup.string().when([], {
      otherwise: Yup.string().required(t('Required')),
      is: () => employee === undefined,
      then: Yup.string().required(t('Required')).test(
        'personal-id-backend-validation',  // Name
        'Esta cèdula ya se encuentra registrada',               // Msg
        async (personalId) => {
          // Res from backend will be flag at res.data.success, true for 
          // username good, false otherwise
          const { data: { data } } = await tracker.post(`employees/testId`, {personal_id: personalId});
          return data.success
        } 
      )
    })
  });

  const defaultValues = {
    name: '',
    lastname: '',
    phonenumber: '',
    salary: '',
    bank_account: '',
    role: '',
    payment_type: '',
    personal_id: '',
    per_capita: '',
    is_nzv: false
  };

  const methods = useForm({
    resolver: yupResolver(EmployeeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
      setValue,
    formState: { isSubmitting },
    watch
  } = methods;

  const watchRole = watch("role");
  const watchPaymentType = watch("payment_type");
  const onSubmit = async (data) => {
    if(data.photos)
      data.photos = data.photos[0];
    else
      data.photos = null;  
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    const response = await doSave('employees', data, t('employee_saved'), '/dashboard/employees', employee, headers, t('employee_save_error'));
    if(response.response.status === 423) {
      setPinDialogMessage(response.response.data.message);
      setShowPinDialog(true);
    }

  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      
      <Stack spacing={3}>
        <RHFFileInput name="photos" fileUrl={(employee && employee.photos[0]) ? employee.photos[0].url : null} />
        {(employee && employee.faces && employee.faces.length) ? (
          <>
          <Chip style={{width: "200px", backgroundColor: 'green', color: 'white', alignSelf: 'center' }} icon={<FaceIcon color='white' />} label={`${t('registered_faces')} (${employee.faces.length})`} />
            

           {employee.faces.map((face) => (
            <Typography style={{textAlign: 'center', display: 'none'}}>{face}</Typography>
        ))}
          </>
        ) : employee && (
          <Chip className='danger' style={{width: "400px", backgroundColor: 'red', color: 'white', alignSelf: 'center' }} icon={<FaceIcon color='white' />} label={t('no_registered_face')} />
        ) }
        

        <Stack spacing={1} direction={"row"}>
          <RHFTextField name="name" label={t('name')} />
          <RHFTextField name="lastname" label={t('lastname')} />
        </Stack>
       
        <RHFTextField type="text" name="personal_id" label={t('personal_id_passport')} />
        <RHFTextField type="number" name="phone_number" label={t('phone_number')} />
        <RHFSelect 
            name="role"
            label={t('Role')} 
            options={roles} />
        {(watchRole === "Administrator") &&
           <RHFTextField type="number" name="pin" label={t('pin')} />
        }
        <RHFTextField type="number" name="salary" label={t('salary')} />
        <RHFTextField type="number" name="per_capita" label={t('per_capita')} />
        <RHFSelect name="payment_type" label={t('payment_type')} options={paymentTypes} />
        {watchPaymentType === 'bank' && 
          <RHFTextField type="number" name="bank_account" label={t('bank_account')} />
        }
        <input type="hidden" name="id" />
        <PinDialog
            open={showPinDialog}
            onClose={() => {setShowPinDialog(false)}}
            onSubmit={handleSubmit(onSubmit)}
            loading={isSubmitting}
            message={pinDialogMessage}
           />
        <RHFCheckbox name="is_nzv" label="TF" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/employees">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
