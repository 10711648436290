import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Stack, Link} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-dropzone-uploader/dist/styles.css'
import { paymentTypes } from '../../../constants';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import RHFSelect from "../../../components/hook-form/RHFSelect";
import { useApi } from '../../../hooks/useApi';
import tracker from "../../../api/tracker";
import RHFFileInput from '../../../components/hook-form/RHFFileInput';
import { PinDialog } from '../../../components/general/PinDialog';

// ----------------------------------------------------------------------

export default function LoanForm({loan}) {
  
  

  const {t} = useTranslation();
  const {doSave} = useApi();
  const [employees, setEmployees] = useState([]);
 
  const fetchEmployees = async () =>{
    const result = await tracker.get('employees')
    const formatEmployee = result.data.data.map((employee)=>( {value:employee.id,label:employee.name}))
    setEmployees(formatEmployee)
  }

  useEffect(() => {
    if(loan){
      const fields = ['total_amount', 'id', 'fortnight_qty']
      setValue("employee_id",loan.employee.id)
      fields.forEach(field => setValue(field, loan[field]));
    }
    fetchEmployees();
  }, [loan])

  const LoanSchema = Yup.object().shape({
    employee_id: Yup.string().required(t('Required')),
    total_amount: Yup.string().required(t('Required')),
    fortnight_qty: Yup.string().required(t('Required')),
  });

  const defaultValues = {
    employee_id: '',
    total_amount: '',
    fortnight_qty: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoanSchema),
    defaultValues,
  });

  const {
    handleSubmit,
      setValue,
    formState: { isSubmitting },
  } = methods;

  
  const onSubmit = async (data) => {
    const response = await doSave('loans', data, t('loan'), '/dashboard/loans', loan);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      
      <Stack spacing={3}>
        <Stack spacing={1} direction={"column"}>
         <RHFSelect 
            name="employee_id"
            label={t('Employee')} 
            options={employees} />
          <RHFTextField name="total_amount" label={t('total_amount')} />
          <RHFTextField name="fortnight_qty" label={t('fortnight_qty')} />
        </Stack>

        <input type="hidden" name="id" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('save')}
        </LoadingButton>
        <Link component={RouterLink} variant="subtitle2" underline="hover" to="/dashboard/loans">
          {t('go_back')}
        </Link>
      </Stack>
    </FormProvider>
  );
}
