
import { FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
// components
import Page from '../../components/Page';
// mock
import tracker from "../../api/tracker";
import LoanForm from "../../sections/@dashboard/loans/LoanForm";




// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EditLoan() {

  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [loan, setLoan] = useState()
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState('1');
  const fetchUserData = async () =>{
      const response = await tracker.get(`loans/${id}`)
    setLoan(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchUserData()
  }, [id])

  return (
    <Page title={t('Loan')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {t('Loan')}
          </Typography>
        </Stack>
        <Card style={{padding: "20px"}}>
          {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
                :  <LoanForm loan={loan} />}
        </Card>
      </Container>
    </Page>
  );
}
