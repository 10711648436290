import { FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';

import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Avatar,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Chip,
  Box,
  Tab,
  Menu
} from '@mui/material';
import { LoadingButton, TabList, TabPanel, TabContext } from "@mui/lab";
// components
import { ConfirmationDialog } from "../../components/general/ConfirmationDialog";
import Page from '../../components/Page';
import tracker from "../../api/tracker";
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import useQuery from "../../hooks/useQuery";
import { ListHead, ListToolbar, MoreMenu } from '../../components/tables';
import { useApi } from "../../hooks/useApi";
import { PinDialog } from "../../components/general/PinDialog";
import FreeSelect from "../../components/hook-form/FreeSelect";
import SortDropdown from "../../components/SortDropdown";

const FileDownload = require('js-file-download');

const override = {  
  margin: "0 auto",
};

export default function PayrollEdit() {
  const [sites ,setSites] = useState([]);
  const { id } = useParams()
  const [fortnight, setFortnight ] = useState(null)
  const [errorMessage, setErrorMessage ] = useState(null)
  const [isNzv, setIsNzv] = useState(false);
  // const [selectedEmployee ,setSelectedEmployee] = useState();
  const [pagination, setPagination] = useState({total: 0, per_page: 0, current_page: 1, last_page: 1});
  const [submiting, setSubmiting] = useState(false);
  const [editingItem, setEditingItem] = useState({id: null, daysWorked: null, doubleHours: null, extraHours: null, employee_id: null, isGeneral: false});
  const [page, setPage] = useState(1);
  const [filter ,setFilter] = useState("");
  const [tabIndex, setTabIndex] = useState('1');
  const [selected, setSelected] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [showIsrDialog, setShowIsrDialog] = useState(false);
  const [showLoanDialog, setShowLoanDialog] = useState(false);
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isrSplit, setIsrSplit] = useState(2);
  const {doSave} = useApi();
  const [loading, setLoading] = useState(false);
  const handleDaysChange = (e) => setEditingItem({...editingItem, daysWorked: e.target.value});
  const handleDoubleHoursChange = (e) => setEditingItem({...editingItem, doubleHours: e.target.value});
  const handleExtraHoursChange = (e) => setEditingItem({...editingItem, extraHours: e.target.value});
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const TABLE_HEAD = [
    { id: 'employee', label: t('employee'), alignRight: false },
    { id: 'days_worked', label: t('days_worked'), alignRight: false },
    { id: 'extra_hours', label: t('extra_hours'), alignRight: false },
    { id: 'double_hours', label: t('double_hours'), alignRight: false },
    { id: 'isr', label: t('isr'), alignRight: false },
    { id: 'employee_loan', label: t('loan'), alignRight: false },
    { id: 'employee_net_total', label: t('employee_net_total'), alignRight: false },
    { id: 'id' },
  ];

  const nzvOptions = [
    { value: false, label: t('regular')},
    { value: true, label: t('is_nzv')},
  ]

  const refreshTable = async () => {
    setIsLoading(true)
    const filter = isNzv ? '/true' : '';
    const url = `payroll/show/${id}${filter}`
  
    const result = await tracker.get(url);
    setFortnight(result.data.data)
    const siteData = result.data.data.sites_payroll;
    setSites(siteData)
    if(siteData.length)
      setTabIndex(result.data.data.sites_payroll[0].construction_site_name)
    setIsLoading(false)
    setShowCompleteDialog(false)
  }

  const downloadFullReport = async () => {
    const filter = isNzv ? '/true' : '';
    const url = `payroll/downloadPayroll/${id}${filter}`
    const headers = {
      responseType: "blob"
    }
    setLoading(true)
    const response = await tracker.get(url, headers)
    setLoading(false)
    FileDownload(response.data, `${isNzv ? 'TF_': ''}${fortnight.name}.xlsx`)
    handleClose()
  }

  const downloadReceipts = async () => {
    const filter = isNzv ? '/true' : '';
    const url = `payroll/downloadReceipts/${id}${filter}`
    const headers = {
      responseType: "blob"
    }
    setLoading(true)
    const response = await tracker.get(url, headers)
    setLoading(false)
    FileDownload(response.data, `${isNzv ? 'TF_': ''}conduces_${fortnight.period}.pdf`)
    handleClose()
  }

  const downloadBankReport = async () => {
    const headers = {
      responseType: "blob"
    }
    setLoading(true)
    const response = await tracker.get(`payroll/downloadBankPayroll/${fortnight.id}`, headers)+
    setLoading(false)
    FileDownload(response.data, `BANCO - ${fortnight.name}.xlsx`)
    handleClose()
  }
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = async () => {
    setSubmiting(true);
    const data = {
      days_worked: editingItem.daysWorked,
      id: editingItem.id,
      extra_hours: editingItem.extraHours,
      double_hours: editingItem.doubleHours
    }
    await doSave(`payroll`, data, t('payroll_info_change_success'), false, editingItem, true); 
    setSubmiting(false);
    setEditingItem(null)
    refreshTable();
  }

  useEffect(() => {
    refreshTable();
  }, [id, isNzv])

  const handleRowEdit = (row, isGeneral) => {
     setEditingItem({doubleHours: row.double_hours, id: row.id, extraHours: row.extra_hours, daysWorked: row.days_worked, employee_id: row.employee_id, isGeneral})
  }

  const handleTabChange = (event, tab) => {
    setTabIndex(tab)
  }

  const handleIsrChange = async () => {
    console.log(editingItem)
    const url = editingItem.isGeneral ? `payroll/splitIsrEmployee/${fortnight.id}/${editingItem.employee_id}` : `payroll/${editingItem.id}/splitIsr`
    setLoading(true)
    const response = await doSave(url, {fortnight_qty: isrSplit}, t('isr_split_success'), false, false, true);
    if(response.response && response.response.status === 423) {
      setErrorMessage(response.response.data.message);
    }
    else {
      setIsrSplit(2)
      setShowIsrDialog(false)
      setEditingItem(null)
      refreshTable()
    }
    setLoading(false)
   
  }

  const handleLoanChange = async () => {
    const url = editingItem.isGeneral ? `payroll/pushLoanEmployee/${fortnight.id}/${editingItem.employee_id}` : `payroll/${editingItem.id}/pushLoan`
    setLoading(true)
    const response = await doSave(url, {fortnight_qty: isrSplit}, t('loan_push_success'), false, false, true);
    if(response.response && response.response.status === 423) {
      setErrorMessage(response.response.data.message);
    }
    else {
      setShowLoanDialog(false)
      setEditingItem(null)
      refreshTable()
    }
    setLoading(false)
  }

  const handleComplete = async () => {
    setIsLoading(true)
    const response = await doSave(`payroll/${fortnight.id}/complete`, {fortnight_qty: isrSplit}, t('fortnight_complete_success'), false, false, true); 
    setIsLoading(false)
    refreshTable()
  }

  const handleGemerate = async () => {
    setLoading(true)
    const response = await doSave(`payroll/generate/${fortnight.id}`, {fortnight_qty: isrSplit}, "La nomina fue generada", false, false, true); 
    setLoading(false)
    refreshTable()
  }

  const handleFilterByName = (event) => {
    setFilter(event.target.value);
  };

  console.log(editingItem)
  return (
    <Page title={t('fortnight_edit')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            {t('fortnights')} {fortnight && fortnight.period} 
          </Typography>
        <LoadingButton
        id="basic-button"
        variant="contained"
        loading={loading}
        color="info"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<Iconify icon="mdi:download"/>}
      >
        Generar Documentos
      </LoadingButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={downloadFullReport}>Reporte Completo</MenuItem>
        <MenuItem onClick={downloadReceipts}>Conduces</MenuItem>
        <MenuItem onClick={downloadBankReport}>Report Banco</MenuItem>
      </Menu>
          {fortnight && fortnight.status !== "Completada" && <Button color="success" style={{color: 'white'}} variant="contained" onClick={() => setShowCompleteDialog(true)} startIcon={<Iconify icon="mdi:check-outline" />}>
            {t('complete_payroll')}
          </Button>}
         
        </Stack>
        
        <Chip variant="outlined" style={{ width: "150px", marginBottom: "25px"}} label={fortnight && fortnight.status} color={fortnight && fortnight.status === 'Completada' ? 'success' : 'warning'} />
        <Stack mb={1} >
        <SortDropdown style={{width: '15%',alignSelf: 'center'}} options={nzvOptions} sort={isNzv} setSort={setIsNzv} />
        </Stack>
       
        
        {isLoading ?  <FadeLoader color={'blue'} loading={isLoading} cssOverride={override} size={50} /> :
        <Card>
            <TabContext value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            {sites.map((site) => (
                <Tab label={t(site.construction_site_name)} value={site.construction_site_name} />
            ))}
              
            </TabList>
          </Box>
          
          {sites.map((site) => (
                <TabPanel value={site.construction_site_name}>
                <Scrollbar>
                   <Typography style={{fontStyle: 'italic', marginLeft: "20px", marginTop: "15px"}} variant="h5" gutterBottom>
                    {t(site.construction_site_name)}
                  </Typography>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    
                    <>
                    <ListHead
                    omitCheckbox
                  headLabel={TABLE_HEAD}/>
                    <TableBody>
                       {site.items.map((row) => {
                          const { employee_name: name, days_worked: daysWorked, extra_hours: extraHours, double_hours: doubleHours, employee_net_total: netTotal,
                            employee_isr: isr, employee_loan: loan} = row;
                          const id = row.id !== null ? row.id : row.employee_id
                          const isItemSelected = selected.indexOf(id) !== -1;
                          const isEditing = editingItem && editingItem.id === id;
                          const isGeneral = site.construction_site_name === 'general_payroll'
                          const menuItems = []
                          if(!isGeneral)
                            menuItems.push((
                              <MenuItem onClick={() => {handleRowEdit(row)}} sx={{ color: 'text.secondary' }}>
                              <ListItemIcon>
                                <Iconify icon="eva:edit-fill" width={24} height={24} />
                              </ListItemIcon>
                              <ListItemText  primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                              ))
                          if(parseFloat(isr.replaceAll(',', ''))> 1)
                            menuItems.push(<MenuItem onClick={() => {setShowIsrDialog(true); handleRowEdit(row, isGeneral)}} sx={{ color: 'text.secondary' }}>
                            <ListItemIcon>
                              <Iconify icon="eva:edit-fill" width={24} height={24} />
                            </ListItemIcon>
                            <ListItemText  primary={t('split_isr')} primaryTypographyProps={{ variant: 'body2' }} />
                          </MenuItem>)
                          if(parseFloat(loan.replaceAll(',', ''))> 1)
                          menuItems.push(<MenuItem onClick={() => {setShowLoanDialog(true); handleRowEdit(row, isGeneral)}} sx={{ color: 'text.secondary' }}>
                          <ListItemIcon>
                            <Iconify icon="eva:edit-fill" width={24} height={24} />
                          </ListItemIcon>
                          <ListItemText  primary={t('push_loan')} primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>)
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell  align="left">{name}</TableCell>
                              {!isEditing ? <>
                                <TableCell  align="left">{daysWorked}</TableCell>
                                <TableCell  align="left">{extraHours}</TableCell>
                                <TableCell  align="left">{doubleHours}</TableCell>
                                  </> : <>
                                  <TableCell  align="left">
                                    <TextField onChange={handleDaysChange}
                                    style={{width: '100px', padding: '-5px', lineHeight: '1'}}
                                    label={t('days_worked')} name="days_worked"
                                    defaultValue={daysWorked} />
                                  </TableCell>
                                  <TableCell  align="left">
                                    <TextField onChange={handleExtraHoursChange}
                                    style={{width: '100px', padding: '-5px', lineHeight: '1'}}
                                    label={t('extra_hours')} name="extra_hours"
                                    defaultValue={extraHours} />
                                  </TableCell> 
                                  <TableCell  align="left">
                                    <TextField onChange={handleDoubleHoursChange}
                                    style={{width: '100px', padding: '-5px', lineHeight: '1'}}
                                    label={t('double_hours')} name="double_hours"
                                    defaultValue={doubleHours} />
                                  </TableCell>  
                                   
                                  </>}
                             
                              <TableCell  align="left">{isr}</TableCell>
                              <TableCell  align="left">{loan}</TableCell>
                              <TableCell  align="left">{netTotal}</TableCell>
                              <TableCell align="right">
                                {isEditing ? (
                                  <LoadingButton style={{marginLeft: 15, marginTop: 10}} size="small" onClick={handleEdit} variant="contained" loading={submiting}>
                                    {t('save')}
                                  </LoadingButton>
                                ) : (
                                  <MoreMenu url={`/dashboard/employees/edit/${id}`} menuItems={menuItems}
                                     />)}
                              </TableCell>
                            </TableRow>
                          );
                        }) }
                      
                    </TableBody>
    
                    {notFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    </>
                      </Table>
                </TableContainer>
              </Scrollbar>
              </TabPanel>
          ))}
        </TabContext>
        <Stack>
        {fortnight && <Button color="warning" style={{color: 'white'}} variant="contained" onClick={handleGemerate} startIcon={<Iconify icon="mdi:cash-sync" />}>
            Generar Quincena
          </Button>}
        </Stack>
        
          <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={showIsrDialog}
          >
            <DialogTitle>{t('split_isr_confirm')}</DialogTitle>
            <DialogContent dividers>
            <Stack spacing={1}>
              <FreeSelect initialValue={2} setValue={setIsrSplit} options={[{value: 2, label: "2"}, {value: 3, label: "3"}, {value: 4, label: "4"}]} />
              {errorMessage && <Alert severity="error"> {errorMessage}</Alert>}
            </Stack>
             
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => {setShowIsrDialog(false)}}>
                {t('cancel')}
              </Button>
              <LoadingButton loading={loading} variant="contained" onClick={handleIsrChange}>{t('save')}</LoadingButton>
            </DialogActions>
          </Dialog>
          <ConfirmationDialog
            open={showCompleteDialog}
            onClose={() => {setShowCompleteDialog(false)}}
            loading={loading}
            onConfirm={() => handleComplete(null)}
            message={`Desea marcar la quincena ${fortnight && fortnight.period} como completada?` }
           />
           <ConfirmationDialog
            open={showLoanDialog}
            onClose={() => {setShowLoanDialog(false)}}
            loading={loading}
            onConfirm={() => handleLoanChange(null)}
            message={`Desea aplazar el descuento de este préstamo para la siguiente quincena?` }
           />
        </Card>}
      </Container>
    </Page>
  );
}
