/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as dateFns from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// material
import {
  Card,
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Typography,
  TableContainer,
  Modal,
  IconButton,
  Avatar,
  Grid,
  TableHead,
  Paper,
  Collapse,
  LinearProgress,
  Popover,
} from '@mui/material';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import Iconify from '../../../components/Iconify';
import tracker from '../../../api/tracker';

const TODAY = new Date();

const TimePickerForm = ({ open, isEntrance, anchorEl, value, handleChange, handleClose, handleSave }) => {
  const { t } = useTranslation();
  const [timePickerEl, setTimePickerElement] = useState(null);

  return (
    <Popover
      elevation={3}
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        handleClose();
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{ backgroundColor: '#02021050' }}
    >
      <Grid item md={12} padding={1}>
        <Typography variant="h5">{`${t('edit')} ${isEntrance ? t('entrance_hour') : t('exit_hour')}`}</Typography>
      </Grid>
      <Grid item md={12} padding={1}>
        <DesktopTimePicker
          value={value}
          onChange={handleChange}
          PopperProps={{
            placement: 'bottom',
            anchorEl: timePickerEl,
          }}
          renderInput={(params) => (
            <TextField {...params} onClick={(event) => setTimePickerElement(event.currentTarget)} />
          )}
        />
      </Grid>
      <Grid item container md={12} padding={2} spacing={1} justifyContent="space-evenly">
        <Grid item container md={4} justifyContent="center">
          <Button variant="outlined" color="inherit" onClick={() => handleClose()}>
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item container md={4} justifyContent="center">
          <Button
            variant="contained"
            onClick={async () => {
              if (handleSave) {
                await handleSave();
              }

              handleClose();
            }}
          >
            {t('save')}
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

const AttendanceRecord = ({ employee, date, sites }) => {
  const { t } = useTranslation();
  const [rowOpen, setRowOpen] = useState(false);
  const [hourData, setHourData] = useState({ hasWarning: false, total: 0 });
  const [sitesCount, setSitesCount] = useState();
  const [isEditingStart, setIsEditingStart] = useState(false);
  const [isEditingEnd, setIsEditingEnd] = useState(false);
  const [dialogEl, setDialogAnchorElement] = useState(null);
  const [customStartDate, setCustomStartDate] = useState();
  const [customEndDate, setCustomEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getHour = (wholeDate) => dateFns.format(new Date(wholeDate), 'hh:mm a');

  useEffect(() => {
    setSitesCount(Object.keys(sites).length);

    const hourResult = Object.values(sites).reduce(
      (accumulated, siteDict) => ({
        hasWarning: accumulated.hasWarning || siteDict.hours <= 0,
        total: accumulated.total + Math.max(0, siteDict.hours),
      }),
      { hasWarning: false, total: 0 }
    );
    setHourData(hourResult);
  }, [sites, date]);

  const updateAttendanceTime = async (attendanceId, targetTime, isEntry = true) => {
    try {
      setIsLoading(true);

      const payload = {};

      if (isEntry) {
        payload.start_hour = getHour(targetTime);
      } else {
        payload.end_hour = getHour(targetTime);
      }

      await tracker.post(`attendance/${attendanceId}/setHours`, {
        ...payload,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TableRow
        key={`date-${date}-header`}
        sx={{
          '& > *': { borderBottom: 'unset' },
          backgroundColor: hourData && hourData.hasWarning ? '#ffebee' : 'white',
        }}
        onClick={() => setRowOpen(!rowOpen)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {rowOpen ? <Iconify icon="octicon:chevron-up-12" /> : <Iconify icon="octicon:chevron-down-12" />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {dateFns.format(dateFns.parse(date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
        </TableCell>
        <TableCell component="th" scope="row">
          {dateFns.format(dateFns.parse(date, 'yyyy-MM-dd', new Date()), 'EEEE', { locale: esLocale })}
        </TableCell>
        <TableCell>{sitesCount}</TableCell>
        <TableCell sx={{ backgroundColor: hourData && hourData.hasWarning ? '#ffcdd2' : 'white' }}>
          <Grid container justifyContent="space-evenly">
            <Grid item xs={1} alignSelf="center">
              {hourData && hourData.total.toFixed(1)}
            </Grid>
            <Grid item xs={1}>
              {hourData.hasWarning && <Iconify icon="ph:warning-fill" sx={{ color: '#c62828', fontSize: '2em' }} />}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow key={`date-${date}-data`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={rowOpen} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {t('projects_breakdown')}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('project')}</TableCell>
                    <TableCell>{t('entrance_hour')}</TableCell>
                    <TableCell>{t('exit_hour')}</TableCell>
                    <TableCell>{t('total_hours')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(sites).map((item) => (
                    <TableRow key={`${date}-${item.site.id}`}>
                      <TableCell component="th" scope="row">
                        {item.site.name}
                      </TableCell>
                      <TableCell sx={{ backgroundColor: !item.start_date ? '#ffebee' : 'white' }}>
                        <Grid container justifyContent="start" alignItems="center">
                          <IconButton
                            onClick={(event) => {
                              setIsEditingStart(true);
                              setDialogAnchorElement(event.currentTarget);
                              setCustomStartDate(item.start_date || null);
                            }}
                          >
                            <Iconify icon="typcn:edit" />
                          </IconButton>
                          {item.start_date ? getHour(item.start_date) : t('no_record')}
                          <TimePickerForm
                            anchorEl={dialogEl}
                            label={customStartDate}
                            open={isEditingStart}
                            value={customStartDate}
                            isEntrance
                            handleClose={() => {
                              setIsEditingStart(false);
                              setCustomStartDate(null);
                            }}
                            handleChange={(newValue) => {
                              setCustomStartDate(newValue);
                            }}
                            handleSave={() => {
                              if (customStartDate) {
                                updateAttendanceTime(item.attendance_id, customStartDate, true);

                                item.start_date = customStartDate;
                              }
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: !item.end_date ? '#ffebee' : 'white' }}>
                        <Grid container justifyContent="start" alignItems="center">
                          <IconButton
                            onClick={(event) => {
                              setIsEditingEnd(true);
                              setDialogAnchorElement(event.currentTarget);
                              setCustomEndDate(item.end_date || null);
                            }}
                          >
                            <Iconify icon="typcn:edit" />
                          </IconButton>
                          {item.end_date ? getHour(item.end_date) : t('no_record')}
                          <TimePickerForm
                            anchorEl={dialogEl}
                            open={isEditingEnd}
                            value={customEndDate}
                            isEntrance={false}
                            handleClose={() => {
                              setIsEditingEnd(false);
                              setCustomEndDate(null);
                            }}
                            handleChange={(newValue) => {
                              setCustomEndDate(newValue);
                            }}
                            handleSave={() => {
                              if (customEndDate) {
                                updateAttendanceTime(item.attendance_id, customEndDate, false);

                                item.end_date = customEndDate;
                              }
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell sx={{ backgroundColor: item.hours < 0 ? '#ffebee' : 'white' }}>
                        {item.hours > 0 ? (
                          item.hours.toFixed(1)
                        ) : (
                          <Iconify icon="ph:warning-fill" sx={{ color: '#c62828' }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function AssistanceModal({ employee, isShowing, onClose }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [days, setDays] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);
  const [datePickerEl, setDatePickerAnchorElement] = useState(null);

  const getAttendances = async () => {
    try {
      setIsLoading(true);
      const attendanceList = await getAttendanceRecords();

      const results = {};

      console.log('RAW', attendanceList);

      // eslint-disable-next-line no-restricted-syntax
      for (const record of attendanceList) {
        const recStartDate = record.star_date;
        const recEndDate = record.end_date;
        const site = record.construction_site;
        const attId = record.id;

        const shortStartDate = recStartDate.slice(0, 10);

        const calcHours =
          // eslint-disable-next-line no-nested-ternary
          recStartDate === null
            ? -1
            : recEndDate === null
            ? -1
            : (new Date(recEndDate) - new Date(recStartDate)) / 1000 / 60 / 60;

        if ([shortStartDate] in results) {
          results[shortStartDate][site.id] = {
            site,
            start_date: recStartDate,
            end_date: recEndDate,
            hours: calcHours,
            attendance_id: attId,
          };
        } else {
          // add site to the list of attendances
          results[shortStartDate] = {
            [site.id]: {
              site,
              start_date: recStartDate,
              end_date: recEndDate,
              hours: calcHours,
              attendance_id: attId,
            },
          };
        }
      }

      console.log('PARSED', results);

      return results;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getAttendanceRecords = async (queryPage = 1) => {
    const sDate = startDate.toISOString().slice(0, 10);
    const eDate = endDate.toISOString().slice(0, 10);

    const response = await tracker.get(
      `attendance?employee_id=${employee.id}&date_filter=start_date,${sDate},${eDate}&page=${queryPage}&page_size=10`
    );
    const data = response.data.data;
    console.log(response.data.meta.current_page, response.data.meta.last_page);

    if (response.data.meta.current_page < response.data.meta.last_page) {
      return data.concat(await getAttendanceRecords(queryPage + 1));
    }
    setDays(response.data.days)
    return data;
  };

  const cleanSelectedParams = () => {
    setStartDate(null);
    setEndDate(null);
    onClose();
    setAttendanceData(null);
  };

  useEffect(() => {
    if (startDate > endDate) {
      setEndDate(endDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate < startDate) {
      setEndDate(endDate);
    }
  }, [endDate]);

  return (
    employee && (
      <Modal
        open={isShowing && employee !== undefined && employee != null}
        onClose={() => {
          cleanSelectedParams();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            maxWidth: '90%',
            border: '1px solid #555',
            boxShadow: 5,
            p: 4,
          }}
          component={Card}
        >
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <Grid item container xs={4}>
                <Typography id="modal-modal-title" variant="h3">
                  {t('attendances')}:
                </Typography>
              </Grid>
              <Grid item container xs={4} flexDirection="row" justifyItems="center">
                <Grid item xs={12} sx={{ marginBottom: '1em' }}>
                  <Typography id="modal-modal-title" variant="h3" textAlign="center">
                    {employee.fullname}
                  </Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="center" sx={{ marginBottom: '1em' }}>
                  {employee.photos && employee.photos.length > 0 ? (
                    <Grid item>
                      <Avatar alt={employee.fullname} src={employee.photos[0].url} sx={{ width: 100, height: 100 }} />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item md={4}>
                <DatePicker
                  disabled={isLoading}
                  label={t('start_date')}
                  value={startDate}
                  maxDate={TODAY}
                  anchorEl={datePickerEl}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} onClick={(event) => setDatePickerAnchorElement(event.currentTarget)} />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <DatePicker
                  disabled={isLoading}
                  label={t('end_date')}
                  value={endDate}
                  minDate={startDate}
                  maxDate={TODAY}
                  anchorEl={datePickerEl}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} onClick={(event) => setDatePickerAnchorElement(event.currentTarget)} />
                  )}
                />
              </Grid>
              <Grid item md={4} sx={{ alignSelf: 'center' }}>
                <Button
                  variant="contained"
                  disabled={startDate == null || endDate == null || isLoading || startDate > endDate}
                  onClick={async () => {
                    const data = await getAttendances();
                    setAttendanceData(data);
                  }}
                  startIcon={<Iconify icon="iconamoon:search-bold" />}
                >
                  {t('search')}
                </Button>
              </Grid>
              <Grid item md={4}>
                {startDate && (
                  <Typography variant="body2">
                    {dateFns.format(startDate, 'EEEE, do LLLL Y', { locale: esLocale })}
                  </Typography>
                )}
              </Grid>
              <Grid item md={4}>
                {endDate && (
                  <Typography variant="body2">
                    {dateFns.format(endDate, 'EEEE, do LLLL Y', { locale: esLocale })}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {attendanceData ? (
              <Grid item container xs={12} justifyContent="center" sx={{ textAlign: 'center', marginTop: '1em' }}>
                <Typography sx={{fontSize: '20px'}} variant="body2">
                  Dias trabajados: {days}
                    </Typography>
                    </Grid>
            ) : ''}

            <Grid item container xs={12} justifyContent="center" sx={{ textAlign: 'center', marginTop: '1em' }}>
              {isLoading ? (
                <>
                  <Grid item xs={10}>
                    <LinearProgress />
                  </Grid>
                </>
              ) : (
                <Grid item xs={10}>
                  {!attendanceData ? (
                    <h2>{t('search_by_selecting_dates')}</h2>
                  ) : (
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>{t('date')}</TableCell>
                            <TableCell>{t('day_title')}</TableCell>
                            <TableCell>{t('project_amount')}</TableCell>
                            <TableCell>{t('hours_amount')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            Object.keys(attendanceData).length === 0 ? (
                              <TableRow>
                                <TableCell>
                                  <h1>{t('no_assistance_records')}</h1>
                                </TableCell>
                              </TableRow>
                            ) : (
                              Object.entries(attendanceData).map(([dateKey, sitesDict]) => (
                                <AttendanceRecord
                                  key={`attendance-date-${dateKey}`}
                                  employee={employee}
                                  date={dateKey}
                                  sites={sitesDict}
                                />
                              ))
                            )
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  );
}
