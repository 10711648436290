import {React} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

export function ConfirmationDialog(props) {
  const { onClose,onConfirm, message, open, loading, ...other } = props;
  const {t} = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{t('confirmation')}</DialogTitle>
      <DialogContent dividers>
        {message}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('cancel')}
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={onConfirm}>{t('confirm')}</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}