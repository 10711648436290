import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, InputAdornment, InputLabel, ListSubheader, Menu, MenuItem, Select, TextField } from '@mui/material';
import Icofont from 'react-icofont';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import SearchIcon from "@mui/icons-material/Search";

// ----------------------------------------------------------------------

RHFMultiSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  hasIcons: PropTypes.bool,
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function RHFMultiSelect({ name, label, options, initialValues, ...other }) {
  const { control, setValue } = useFormContext();
  const {t} = useTranslation();
  const [selectedOption, setSelectedOption] = useState(options.length ? options[0].value : "");
  const [optionList, setOptionList] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setValue(name, selectedList.map(value => value.value))
}

const onRemove = (selectedList, selectedItem) => {
  setValue(name, selectedList.map(value => value.value))
}

  useEffect(() => {

  }, [initialValues, options])

  return (
    
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        console.log(field.value)
        return (
        <FormControl>
          <Multiselect
            options={options} // Options to display in the dropdown
            selectedValues={initialValues}
            onRemove={onRemove}
            placeholder={label}
            onSelect={onSelect} // Function will trigger on select event
            displayValue="label" // Property name to display in the dropdown options
    />
        </FormControl>
       
      )}}
    />
  );
}
