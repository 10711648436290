import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import Icofont from 'react-icofont';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  hasIcons: PropTypes.bool,
};

export default function RHFSelect({ name, hasIcons, options, ...other }) {
  const { control } = useFormContext();
  const {t} = useTranslation();

  return (
    
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
        <InputLabel id="demo-simple-select-label">{other.label}</InputLabel>
        <Select
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helpertext={error?.message}
          {...other}
          labelId='demo-simple-select-label'
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {hasIcons && <Icofont icon={option.value} size={2} sx={{ ml: 20 }} />}&nbsp;&nbsp;&nbsp;&nbsp;
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
       
      )}
    />
  );
}
