
import { FadeLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  Tab,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
// components
import Page from '../../components/Page';
// mock
import tracker from "../../api/tracker";
import ConstructionForm from "../../sections/@dashboard/constructions/ConstructionForm";
import ConstructionEmployeeForm from "../../sections/@dashboard/constructions/ConstructionEmployeeForm";




// ---------------------------------------------------------------------


const override = {
  // display: "block",
  margin: "0 auto",
  // borderColor: "red",
};


export default function EditConstruction() {

  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [construction, setConstruction] = useState()
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState('1');
  const fetchUserData = async () =>{
      const response = await tracker.get(`construction_sites/${id}`)
    setConstruction(response.data.data)
    setLoading(false)
  }
  const handleChange = (event, value) => {
    setTabIndex(value)
  }

  useEffect(() => {
    fetchUserData()
  }, [id])

  return (
    <Page title={t('construction_form')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            {t('construction_form')}
          </Typography>
        </Stack>
        <Card style={{padding: "20px", minHeight: "400px"}}>
        <TabContext value={tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('employees')} value="1" />
              <Tab label={t('site_info')} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
              <ConstructionEmployeeForm construction={construction} />
          </TabPanel>
          <TabPanel value="2">
          {loading ? <FadeLoader color="blue" cssOverride={{margin: "0 auto"}} loading={loading} />
                :  <ConstructionForm construction={construction} />}
            
          </TabPanel>
        </TabContext>

          
        </Card>
      </Container>
    </Page>
  );
}
